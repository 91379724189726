// noinspection JSValidateTypes

import _ from 'lodash';
import React, { Component } from 'react';
import { render } from 'react-dom';
import io from'socket.io-client';
import { BrowserRouter, Route, Link, Switch, Redirect } from 'react-router-dom';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

import CrawlingViewer from "./pages/CrawlingViewer";
import CrawlingWikiViewer from "./pages/CrawlingWikiViewer";
import DataFeedbackManager from './pages/DataFeedbackManager';
import DataIssuesManager from './pages/DataIssuesManager';
import DataProcessDefinitionManager from './pages/DataProcessDefinitionManager';
import DataProcessManager from './pages/DataProcessManager';
import DataTaskBatchWork from "./pages/DataTaskBatchWork";
import DataTasksBatchesManager from "./pages/DataTasksBatchesManager";
import DataTasksBatchesStatistics from "./pages/DataTasksBatchesStatistics";
import DataTasksManager from './pages/DataTasksManager';
import DataViewsManager from './pages/DataViewsManager';
import DeletedLegosManager from './pages/DeletedLegosManager';
import EmailLogViewer from "./pages/EmailLogViewer";
import EmailVariationsViewer from "./pages/EmailVariationsViewer";
import FacetsManager from "./pages/FacetsManager";
import FuseboxManager from './pages/freelancers/FuseboxManager';
import ImgClustersManager from './pages/ImgClustersManager';
import LegoArticlesManager from "./pages/freelancers/LegoArticlesManager";
import LegoDefinitionsManager from "./pages/freelancers/LegoDefinitionsManager";
import LegoSpecsManager from "./pages/freelancers/LegoSpecsManager";
import LegosManager from './pages/LegosManager';
import LinguisticComparison from './pages/LinguisticComparison';
import LoginPage from './pages/LoginPage';
import ManualsBatchUpload from "./pages/ManualsBatchUpload";
import ManualsManager from "./pages/ManualsManager";
import ManualsViewer from "./pages/ManualsViewer";
import PageNotFound from "./pages/PageNotFound";
import RegisteredUserStatsViewer from "./pages/RegisteredUserStatsViewer";
import ScriptsManager from './pages/ScriptsManager';
import SitePagesQualitativeSample from './pages/SitePagesQualitativeSample';
import StatsManager from './pages/StatsManager';
import SymptomsManager from './pages/SymptomsManager';
import TaggedReportsExplorer from './pages/TaggedReportsExplorer';
import TaggedSemanticsExplorer from "./pages/TaggedSemanticsExplorer";
import TestFuseboxEditor from './pages/TestFuseboxEditor';
import TestFuseboxCalibration from './pages/TestFuseboxCalibration';
import TestPage from './pages/TestPage';
import TestPerceptualHash from './pages/TestPerceptualHash';
import TrimsManager from './pages/TrimsManager';
import TrimsViewer from './pages/subpages/TrimsViewer';
import UserConversationsViewer from './pages/UserConversationsViewer';
import UserTrimsViewer from "./pages/UserTrimsViewer";
import LegosOtherManager from './pages/LegosOtherManager';
import GenerationsManager from './pages/GenerationsManager';
import GenerationsByCountryManager from './pages/GenerationsByCountryManager';

import favicon from '../admin-front/public/icon-lego-worker.png?url';
import ContributionsManager from './pages/ContributionsManager';
import ModelsManager from './pages/ModelsManager';

const { protocol, hostname, port } = document.location;

if (port) {
  // Local development
  window.serverBaseUrl = `${protocol}//${hostname}:3003`;
  // Use development icon
  document.querySelector("link[rel~='icon']").href = favicon;
} else {
  // Production
  window.serverBaseUrl = document.location.origin;
}

// This will work either with http://localhost or https://kb.opinautos.com
window.socket = io(window.serverBaseUrl, {
  transports: ['websocket'],
  forceNew: true
});
window.client = feathers();

client.configure(socketio(socket, {
  timeout: 60000
}));

window.client.configure(auth({}));

window.initialSocketStatus = 'connecting';
socket.on('connect', () => window.initialSocketStatus = 'connected');

///////////////////////////////////////////////////////////////////

class App extends Component {
  render() {
    let {user, config} = this.props;

    // TODO: Fast hack. The correct way is creating a context that the page can consume
    window.config = _.extend(window.config || {}, config);
    // window.document.title = config.legoAdminTitle;

    if(user.isAdmin) {
      return <Switch>
        <Route exact path='/' render={() => <Redirect to="/legos"/>}/>

        <Route exact path='/test' render={({ match }) => <TestPage/>}/>
        <Route exact path='/test/perceptualhash' component={TestPerceptualHash}/>

        <Route exact path='/legos' render={() => <Redirect to="/legos/all"/>}/>
        <Route exact path='/legos/all' component={LegosManager}/>
        <Route exact path='/legos/deleted' component={DeletedLegosManager}/>
        <Route exact path='/legos/other' component={LegosOtherManager}/>
        <Route exact path='/legos/fusebox' component={FuseboxManager}/>
        <Route exact path='/legos/specs' component={LegoSpecsManager}/>
        <Route exact path='/legos/articles' component={LegoArticlesManager}/>
        <Route exact path='/legos/definitions' component={LegoDefinitionsManager}/>
        <Route exact path='/legos/data-feedback' component={DataFeedbackManager}/>
        <Route exact path='/legos/contributions' component={ContributionsManager}/>
        <Route exact path='/legos/data-issues' component={DataIssuesManager}/>
        <Route exact path='/legos/image-clusters' component={ImgClustersManager}/>

        <Route exact path='/dataviews' component={DataViewsManager}/>

        <Route exact path='/stats' render={() => <Redirect to="/stats/trims"/>}/>
        <Route exact path='/stats/:category' component={StatsManager}/>

        <Route exact path='/trims' component={TrimsManager}/>
        <Route exact path='/trims/viewer' component={TrimsViewer}/>

        <Route exact path='/tasks' render={() => <Redirect to="/tasks/batches"/>}/>
        <Route exact path='/tasks/workflows' component={DataProcessDefinitionManager}/>
        <Route exact path='/tasks/processes' component={DataProcessManager}/>
        <Route exact path='/tasks/list' component={DataTasksManager}/>
        <Route exact path='/tasks/batches' component={DataTasksBatchesManager}/>
        <Route exact path='/tasks/contributors' component={DataTasksBatchesStatistics}/>
        <Route exact path='/tasks/batches/:batchId' component={DataTaskBatchWork}/>
        <Route exact path='/tasks/pages-sampling' component={SitePagesQualitativeSample}/>

        <Route exact path='/fusebox' component={TestFuseboxEditor}/>
        <Route exact path='/fusebox-calibration' component={TestFuseboxCalibration}/>


        <Route exact path='/manuals' render={() => <Redirect to="/manuals/viewer"/>}/>
        <Route exact path='/manuals/viewer' component={ManualsViewer}/>
        <Route exact path='/manuals/batch-upload' component={ManualsBatchUpload}/>
        <Route exact path='/manuals/edit' component={ManualsManager}/>

        <Route exact path='/models' render={() => <Redirect to="/models/all"/>}/>
        <Route exact path='/models/all' component={ModelsManager}/>
        <Route exact path='/models/gens' component={GenerationsManager}/>
        <Route exact path='/models/genbycountry' component={GenerationsByCountryManager}/>

        <Route exact path='/crawl' render={() => <Redirect to="/crawl/cars"/>}/>
        <Route exact path='/crawl/cars' component={CrawlingViewer}/>
        <Route exact path='/crawl/wikiboxes' component={CrawlingWikiViewer}/>
        <Route exact path='/crawl/usertrims' component={UserTrimsViewer}/>


        <Route exact path='/facets' component={FacetsManager}/>

        <Route exact path='/emails' render={() => <Redirect to="/emails/logs"/>}/>
        <Route exact path='/emails/logs' component={EmailLogViewer}/>
        <Route exact path='/emails/variations' component={EmailVariationsViewer}/>
        <Route exact path='/emails/conversations' component={UserConversationsViewer}/>

        <Route exact path='/tagging' render={() => <Redirect to="/tagging/explore"/>}/>
        <Route exact path='/tagging/explore' component={TaggedReportsExplorer}/>
        <Route exact path='/tagging/symptoms' render={() => <Redirect to="/tagging/symptoms/aire-acondicionado"/>}/>
        <Route exact path='/tagging/symptoms/:name' component={SymptomsManager}/>
        <Route exact path='/tagging/linguistics' component={TaggedSemanticsExplorer}/>
        <Route exact path='/tagging/linguistic-comparison' component={LinguisticComparison}/>

        <Route exact path='/scripts' component={ScriptsManager}/>

        <Route exact path='/users' component={RegisteredUserStatsViewer}/>

        {/*<Route path='/diagnostic/:modelId' render={({ match }) => <DiagnosticModel modelId={match.params.modelId}/>}/>*/}
        {/*<Route exact path='/magic/:query' component={({match}) => <MagicPage query={match.params.query} showQuery={true}/>}/>*/}

        <Route path='*' exact={true} component={PageNotFound}/>
      </Switch>;
    } else if(user.isContributor) {
      return <Switch>
        <Route exact path='/' render={() => <Redirect to="/legos"/>}/>

        <Route exact path='/legos' render={() => <Redirect to="/legos/specs"/>}/>
        <Route exact path='/legos/specs' component={LegoSpecsManager}/>
        <Route exact path='/legos/fusebox' component={FuseboxManager}/>
        <Route exact path='/legos/data-issues' component={DataIssuesManager}/>
        <Route exact path='/legos/image-clusters' component={ImgClustersManager}/>
        <Route exact path='/legos/data-feedback' component={DataFeedbackManager}/>
        <Route exact path='/legos/other' component={LegosOtherManager}/>

        <Route exact path='/tasks' render={() => <Redirect to="/tasks/batches"/>}/>
        <Route exact path='/tasks/batches' component={DataTasksBatchesManager}/>
        <Route exact path='/tasks/batches/:batchId' component={DataTaskBatchWork}/>
        <Route exact path='/tasks/contributors' component={DataTasksBatchesStatistics}/>

        <Route exact path='/manuals' render={() => <Redirect to="/manuals/viewer"/>}/>
        <Route exact path='/manuals/viewer' component={ManualsViewer}/>
        <Route exact path='/manuals/batch-upload' component={ManualsBatchUpload}/>
        <Route exact path='/manuals/edit' component={ManualsManager}/>

        <Route path='*' exact={true} component={PageNotFound}/>
      </Switch>
    }
  }
}

let rootElement = document.getElementById('root');

function renderPage(user, config) {
  render((<BrowserRouter>
    <App user={user} config={config}/>
  </BrowserRouter>), rootElement);
}

// Authenticate with the local email/password strategy
let accessToken = localStorage['accessToken'];
if (!localStorage['accessToken']) {
  render((<LoginPage  key={'initial'} initialEmail={localStorage['lastAccessEmail']}/>), rootElement);
} else {
  console.log('Reusing access token from localStorage');
  render((<LoginPage  key={'connecting'} initialEmail={localStorage['lastAccessEmail']} connecting={true}/>), rootElement);

  window.client.authenticate({ strategy: 'jwt', accessToken: localStorage['accessToken'] }).then(({config}) => {
    try {
      window.user = JSON.parse(localStorage['user']);
      renderPage(user, config);
    } catch (e) {
      render((
        <div className={'alert alert-danger m-3'}>
          There was a problem loading admin page:
          <br/>
          <br/>
          <pre>{e.toString() + e.stack}</pre>
        </div>
      ), rootElement);
    }
  }).catch(err => {
    render((<LoginPage key={'error'} initialEmail={localStorage['lastAccessEmail']} connecting={false}/>), rootElement);
    return console.error('Authentication error', err);
  });
}

